<template>
  <div class="home" v-if="this.$store.state.user.role.startsWith('management')">
    <div class="pa-4">
      <v-form ref="form">
        <v-card>
          <v-card-title>設定</v-card-title>

          <v-card-subtitle>データベース</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  class="centered-input" 
                  type="number"
                  min="50" 
                  label="検索結果の取得上限件数"
                  v-model="currentItem.query_limit"
                  :rules="queryLimitRules"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  class="centered-input" 
                  type="number"
                  min="0" 
                  label="検索結果の取得上限件数（医療機関）"
                  v-model="currentItem.query_limit_for_customer_analyses"
                  :rules="queryLimitRulesForCustomer"
                />
              </v-col>
              <v-col cols="8">
                <label>心電図解析管理画面で利用中（0を指定すると上限無しで無制限となります）</label>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-subtitle>デバイス未受付アラート日数</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  class="centered-input" 
                  type="number" 
                  min="4" 
                  v-model="currentItem.device_collect_grace_days"
                  :rules="deviceCollectGraceDaysRules"
                />
              </v-col>
              <v-col cols="8">
                <label>検査開始登録後、患者様からデバイス受付センターに心電計が郵送されず、未受付のまま指定した日数が経過した場合にメールを送信します。</label>
                <label>メールは該当心電計に対して登録された担当医師全員に送信されます。</label>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-subtitle>各種履歴の保管日数</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  class="centered-input" 
                  type="number" 
                  min="1" 
                  v-model="currentItem.storage_period.operation_history"
                  :rules="storagePeriodRules"
                />
              </v-col>
              <v-col cols="8">
                <label>操作履歴を残しておく日数です。定期実行するスケジューラーにより、設定された日数よりも過去の記録は削除されます。</label>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-subtitle>通知の保管日数</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                <v-text-field 
                  class="centered-input" 
                  type="number" 
                  min="1" 
                  v-model="currentItem.storage_period.notifications"
                  :rules="storagePeriodRules"
                />
              </v-col>
              <v-col cols="8">
                <label>ホーム画面に表示される通知を残しておく日数です。定期実行するスケジューラーにより、設定された日数よりも過去の通知は、未確認の通知でも削除されます。</label>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="centered-input" color="warning darken-1" text @click="saveConfigs">
              保存する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>    
    </div>
  </div>
</template>

<script>


export default {
  name: 'configurations',
  components: {
    
  },
  data () {
    return {
      currentItem: {
        storage_period: {
          operation_history: null,
          notifications: null,
        }
      },
      queryLimitRules: [
        v => {
              v = String(v);
              if (v.trim() === '') return '入力は必須です。';
              v = v.trim();
              if (!/^[0-9]+$/.test(v)) return '数字のみ入力してください';
              if (parseInt(v) < 50) return '50以上を指定してください';
              return true;
            },
      ],
      queryLimitRulesForCustomer: [
        v => {
              v = String(v);
              if (v.trim() === '') return '入力は必須です。';
              v = v.trim();
              if (!/^[0-9]+$/.test(v)) return '数字のみ入力してください';
              if (parseInt(v) < 0) return '0以上を指定してください';
              return true;
            },
      ],
      deviceCollectGraceDaysRules: [
        v => {
              v = String(v);
              if (v.trim() === '') return '入力は必須です。';
              v = v.trim();
              if (!/^[0-9]+$/.test(v)) return '数字のみ入力してください';
              if (parseInt(v) < 4) return '4日以上を指定してください';
              return true;
            },
      ],
      storagePeriodRules: [
        v => {
              v = String(v);
              if (v.trim() === '') return '入力は必須です。';
              v = v.trim();
              if (!/^[0-9]+$/.test(v)) return '数字のみ入力してください';
              if (parseInt(v) < 1) return '1日以上を指定してください';
              return true;
            },
      ],
    }
  },
  computed: {
  },
  methods: {
    async saveConfigs(){
      // 入力エラーがある場合は終了
      if (!this.$refs[`form`].validate()) return;

      // 保存確認
      const result = await this.$root.$confirm(`確認`, `保存してもよろしいですか？`);
      if (!result) return;

      try {
        // 各種型変換など
        this.currentItem.query_limit = parseInt(this.currentItem.query_limit);
        this.currentItem.query_limit_for_customer_analyses = parseInt(this.currentItem.query_limit_for_customer_analyses);
        this.currentItem.device_collect_grace_days = parseInt(this.currentItem.device_collect_grace_days);
        this.currentItem.storage_period.operation_history = parseInt(this.currentItem.storage_period.operation_history);
        this.currentItem.storage_period.notifications = parseInt(this.currentItem.storage_period.notifications);
        const beforeUpdate = this.$store.state.configs;  // 操作記録のための更新前情報取得
        this.$store.dispatch('saveConfigs', this.currentItem);
        // 操作記録
        this.$functions.log({
          tags: ['config', 'update'],
          message: `設定を更新しました。`,
          item: { 
            before: {
              query_limit: beforeUpdate.query_limit,
              query_limit_for_customer_analyses: beforeUpdate.query_limit_for_customer_analyses,
              device_collect_grace_days: beforeUpdate.device_collect_grace_days,
              storage_period: {
                operation_history: beforeUpdate.storage_period.operation_history,
                notifications: beforeUpdate.storage_period.notifications,
              },
            },
            after: {
              query_limit: this.currentItem.query_limit,
              query_limit_for_customer_analyses: this.currentItem.query_limit_for_customer_analyses,
              device_collect_grace_days: this.currentItem.device_collect_grace_days,
              storage_period: {
                operation_history: this.currentItem.storage_period.operation_history,
                notifications: this.currentItem.storage_period.notifications,
              },
            },
          }
        });
        await this.$root.$alert('成功', '保存が完了しました。');
      } catch (e) {
        await this.$root.$alert('エラー', '保存に失敗しました。' + e);
      }
    },
  },
  mounted() {
    // 設定
    this.currentItem = JSON.parse(JSON.stringify(this.$store.state.configs));
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setConfigs') {
        this.currentItem = JSON.parse(JSON.stringify(state.configs));
      }
    });
  },
  beforeDestroy() {

  }
}
</script>

<style scoped>
h1 {
  margin: 1em;
  text-align: center;
  font-family:Arial, Helvetica, sans-serif
}

h2 {
  margin: 1em;
  text-align: center;
  font-family:Arial, Helvetica, sans-serif
}

</style>

<style lang="scss">
@import '@/sass/variables.scss';

.centered-input input {
  text-align: center
}
</style>
